<template>
  <div class="w-100">
    <b-row class="no-gutters">
      <b-col>
        <h1 class="font-weight-bold header-main text-uppercase mb-3">
          ข้อมูลหมวดหมู่ป้ายสินค้า
        </h1>
      </b-col>
    </b-row>
    <div class="bg-white p-3" v-if="$isLoading">
      <b-row class="pl-1">
        <b-col cols="6">
          <div class="panel d-flex align-items-md-center">
            <b-form-checkbox
              size="lg"
              id="sameLang"
              class="mt-0 mt-sm-3"
              @change="useSameLanguage"
              v-model="form.isSameLanguage"
              >ใช้เหมือนกันทุกภาษา
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col cols="6" class="text-right">
          <div class="panel">
            <b-button
              type="button"
              class="btn btn-language"
              v-for="(language, index) in languageList"
              v-bind:key="index"
              v-bind:class="[languageActive == language.id ? 'active' : '']"
              @click="changeLanguage(language.id, index)"
              :disabled="form.isSameLanguage ? true : false"
            >
              <span class="text-uppercase">{{ language.nation }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col md="6">
          <div
            v-for="(item, index) in form.tagGroupTranslations"
            v-bind:key="index"
          >
            <div
              v-bind:class="[languageActive == item.languageId ? '' : 'd-none']"
            >
              <InputText
                textFloat="ชื่อหมวดหมู่"
                placeholder="ชื่อหมวดหมู่"
                type="text"
                name="name"
                v-model="item.name"
                @onKeyup="setAlttagByLang(item.name)"
                isRequired
                :isValidate="
                  $v.form.tagGroupTranslations.$each.$iter[index].name.$error
                "
                :v="$v.form.tagGroupTranslations.$each.$iter[index].name"
              />
            </div>
          </div>
        </b-col>
        <b-col md="6">
          <div
            v-for="(item, index) in form.tagGroupTranslations"
            v-bind:key="index"
          >
            <div
              v-bind:class="[languageActive == item.languageId ? '' : 'd-none']"
            >
              <InputText
                textFloat="URL Key"
                placeholder="URL Key"
                type="text"
                name="name"
                v-model="form.urlKey"
                isRequired
                :isValidate="$v.form.urlKey.$error"
                :v="$v.form.urlKey"
              />
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <InputText
            textFloat="ลำดับการแสดง"
            placeholder="ลำดับการแสดง"
            type="text"
            name="sortorder"
            @onKeypress="isNumber($event)"
            v-model="form.sortOrder"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <UploadFile
            textFloat="ภาพหมวดหมู่"
            placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
            format="image"
            name="thumbnail"
            text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 500 x 500 px ขนาดไม่เกิน 10 MB"
            isRequired
            v-on:onFileChange="onImageChange"
            v-on:delete="deleteImage"
            :fileName="images"
          />
          <div class="w-25">
            <div
              class="preview-box ratio-4-3-pb mt-4"
              v-bind:style="{ 'background-image': 'url(' + images + ')' }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingImage"
              />
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col md="6">
          <label class="font-weight-bold main-label">
            สถานะการแสดงผล
            <span class="text-danger">*</span>
          </label>
          <div>
            <b-form-checkbox
              switch
              v-model="display"
              class="radio-active"
              size="lg"
            >
              <span class="ml-2 main-label">{{
                display ? "ใช้งาน" : "ไม่ใช้งาน"
              }}</span>
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col md="6">
          <div class="d-flex justify-content-end">
            <b-button class="btn-main" @click="openModalProduct()"
              >เพิ่มสินค้า</b-button
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-table
            class="table-list"
            striped
            responsive
            hover
            :items="productGroups"
            :fields="fields"
            :busy="isBusy"
            show-empty
            empty-text="ไม่พบข้อมูล"
          >
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
            <template v-slot:cell(imageUrl)="data">
              <div class="position-relative">
                <div
                  class="square-box b-contain"
                  v-bind:style="{
                    'background-image': 'url(' + data.item.imageUrl + ')',
                  }"
                ></div>
              </div>
            </template>
            <template v-slot:cell(name)="data">
              <p class="mb-0 nobreak two-lines">{{ data.item.name }}</p>
            </template>
            <template v-slot:cell(sku)="data">
              <p class="mb-0 nobreak two-lines">{{ data.item.sku }}</p>
            </template>
            <template v-slot:cell(price)="data">
              <span> ฿ {{ data.item.price | numeral("0,0.00") }} </span>
            </template>
            <template v-slot:cell(action)="data">
              <div class="text-center">
                <b-button
                  variant="link"
                  @click="deleteData(data.item.id)"
                  class="text-dark px-1 py-0"
                >
                  ลบ
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col md="6">
          <b-button
            v-if="isEdit"
            class="btn btn-danger btn-details-set mr-md-2"
            :disabled="isDisable"
            @click="openModalDelete(form)"
            >ลบ</b-button
          >
          <router-link to="/category/tags">
            <b-button :disabled="isDisable" class="btn-details-set btn-cancel"
              >ย้อนกลับ</b-button
            >
          </router-link>
        </b-col>
        <b-col md="6" class="text-sm-right">
          <button
            type="button"
            @click="checkForm(0)"
            :disabled="isDisable"
            class="btn btn-main btn-details-set ml-md-2 text-uppercase"
          >
            บันทึก
          </button>
          <button
            type="button"
            @click="checkForm(1)"
            :disabled="isDisable"
            class="btn btn-main btn-details-set ml-md-2 text-uppercase"
          >
            บันทึกและออก
          </button>
        </b-col>
      </b-row>
    </div>

    <ModalSku
      ref="ModalSku"
      @handleData="handleData"
      :productSelectIds="form.productIds"
    />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="isModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { required } from "vuelidate/lib/validators";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalSku from "@/views/pages/tags/ModalSku";
import Vue from "vue";
import UploadFile from "@/components/inputs/UploadFile";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";

export default {
  name: "CategoryNewsDetails",
  components: {
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    ModalSku,
    UploadFile,
    ModalAlertConfirm,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoadingMobileImage: false,
      isEdit: false,
      modalMessage: "",
      isDisable: false,
      isSameLanguage: false,
      productGroups: [],
      languageList: [],
      languageActive: 1,
      formDelete: {
        id: "",
      },
      display: false,
      isBusy: false,
      fields: [
        { key: "ids", label: "", class: "w-50px text-nowrap" },
        { key: "imageUrl", label: "ภาพประกอบ", class: "w-100px text-nowrap" },
        {
          key: "name",
          label: "ชื่อสินค้า",
          class: "w-100px text-nowrap",
        },
        { key: "sku", label: "SKU", class: "w-100px text-nowrap" },
        { key: "price", label: "ราคาขาย", class: "w-100px text-nowrap" },
        { key: "action", label: "", class: "w-100px text-nowrap" },
      ],
      filter: {
        PageNo: 1,
        PerPage: -1,
        Search: "",
        ProductSelectIds: [],
      },
      imgTypeId: 1,
      images: "",
      isLoadingImage: false,
      form: {
        id: 0,
        display: 0,
        sortOrder: 0,
        isSameLanguage: true,
        imageUrl: "",
        tagGroupTranslations: [
          {
            name: "",
            languageId: 1,
            tagGroupId: 0,
            updatedBy: "",
            createdTime: "",
            updatedTime: "",
          },
          {
            name: "",
            languageId: 2,
            tagGroupId: 0,
            createdBy: "",
            updatedBy: "",
            createdTime: "",
            updatedTime: "",
          },
        ],
        productIds: [],
        urlKey: "",
        createdBy: "",
        updatedBy: "",
        createdTime: "",
        updatedTime: "",
      },
    };
  },
  validations: {
    form: {
      tagGroupTranslations: {
        $each: {
          name: { required },
        },
      },
      urlKey: { required },
    },
  },
  created: async function () {
    await this.getData();
    await this.changeLanguage(1, 0);
    this.isEdit = this.id == 0 ? false : true;
  },
  methods: {
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.imageUrl = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var imgData = {
        base64: img,
        type: this.imgTypeId,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    deleteImage() {
      this.images = "";
      this.form.imageUrl = "";
    },
    getData: async function () {
      this.$isLoading = false;

      let languages = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/language`,
        null,
        this.$headers,
        null
      );
      if (languages.result == 1) {
        this.languageList = languages.detail;
      }

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Tag/${this.id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = data.detail;
        this.images = this.form.imageUrl;
        this.display = this.form.display === 1 ? true : false;
        if (this.form.id == 0) {
          this.form.isSameLanguage = true;
        }
        if (this.form.productIds.length > 0) {
          await this.setProduct(this.form.productIds);
        }
      }
      this.$isLoading = true;
    },
    setProduct: async function (data) {
      this.isBusy = true;
      this.filter.ProductSelectIds = data;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Tag/ProductListSelect`,
        null,
        this.$headers,
        this.filter
      );

      if (resData.result == 1) {
        this.productGroups = resData.detail.dataList;
      }
      this.isBusy = false;
    },
    openModalProduct() {
      this.$refs.ModalSku.show();
    },
    handleData(data) {
      if (this.productGroups.length > 0) {
        for (let index = 0; index < data.length; index++) {
          let find = this.productGroups.findIndex((e) => {
            return e.id === data[index].id;
          });
          if (find === -1) this.productGroups.push(data[index]);
        }
      } else this.productGroups = data;

      if (this.productGroups.length > 0) {
        let productGroups = this.productGroups.filter((item) => {
          return this.form.productIds.indexOf(item.id) === -1;
        });

        for (const p of productGroups) {
          this.form.productIds.push(p.id);
        }
      }
    },
    async deleteData(id) {
      this.productGroups = await this.productGroups.filter((el) => el.id != id);
      this.form.productIds = await this.form.productIds.filter(
        (el) => el != id
      );
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changeSameLang: function (value) {
      this.form.isSameLanguage = value;
    },
    changeLanguage(id, index) {
      if (!this.form.isSameLanguage) {
        this.languageActive = id;
      }
    },
    openModalDelete(data) {
      let name = "";
      for (const i of data.tagGroupTranslations) {
        if (i.languageId == this.languageActive) name = i.name;
      }
      this.modalMessage = `คุณต้องการลบหมวดหมู่ ${name} ใช่หรือไม่?`;
      this.formDelete.id = data.id;
      this.$refs.isModalAlertConfirm.show();
    },
    btnDelete: async function () {
      this.$refs.isModalAlertConfirm.hide();

      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/tag/Delete/${this.formDelete.id}`,
        null,
        this.$headers,
        null
      );
      console.log(resData);
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$router.push({
            path: `/category/tags`,
          });
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    useSameLanguage: async function () {
      Vue.nextTick(() => {
        if (this.form.isSameLanguage) {
          this.form.mainLanguageId = this.languageActive;
          let data = this.form.tagGroupTranslations.filter(
            (val) => val.languageId == this.form.mainLanguageId
          );

          if (data.length == 1) {
            data = data[0];
            for (
              let index = 0;
              index < this.form.tagGroupTranslations.length;
              index++
            ) {
              this.form.tagGroupTranslations[index].name = data.name;
            }
          }
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.languageActive);

          let data = this.form.tagGroupTranslations.filter(
            (val) => val.languageId != this.form.mainLanguageId
          );
          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              data.altTag = "";
            }
          }
        }
      });
    },
    checkForm: async function (flag) {
      this.form.display = this.display ? 1 : 0;
      if (this.form.isSameLanguage) {
        await this.useSameLanguage();
      }

      this.$v.$touch();
      if (this.$v.$error) {
        await this.checkValidateTranslationList();
        return;
      }
      this.modalAlertShow = false;
      this.flag = flag;
      this.submit();
    },
    checkValidateTranslationList: async function () {
      let isError = false;
      this.languageList.forEach((element, index) => {
        if (!isError) {
          if (this.$v.form.tagGroupTranslations.$each.$iter[index].$error) {
            this.languageActive =
              this.$v.form.tagGroupTranslations.$model[index].languageId;

            isError = true;
          }
        }
      });
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      const baslUrl = `${this.$baseUrl}/api/Tag/Save`;
      let data = await this.$callApi(
        "post",
        baslUrl,
        null,
        this.$headers,
        this.form
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();

      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();

        if (this.flag == 1) {
          setTimeout(() => {
            this.$router.push({
              path: `/category/tags`,
            });
          }, 3000);
        } else {
          setTimeout(() => {
            this.$refs.modalAlert.hide();
          }, 3000);

          if (this.id > 0) {
            this.getData();
          }
        }
      } else {
        this.modalMessage = data.detail;
        this.$refs.modalAlertError.show();
      }
    },
    setAlttagByLang: function (value) {
      this.form.urlKey = value.replace(/ /g, "-");
    },
  },
};
</script>

<style scoped>
::v-deep .square-box {
  padding-top: 60% !important;
}

.panel {
  font-size: 17px;
  color: #16274a;
}
</style>
